<template>
  <div id="Home" class="element">
    <HomeCom />
  </div>
</template>

<script>
    import HomeCom from '@/components/HomeCom.vue';
    export default {
        components: {
            HomeCom
        },
        data() {
            return {

            }
        },
        created() {
            setTimeout(() => {
                if(localStorage.getItem("empid") != null && localStorage.getItem("password") && localStorage.getItem("companycode")) {
                    this.$router.push('/dashboard');
                }else{
                    this.$router.push('/login');
                }
            }, 1000);
        }
    }
</script>

<style scoped lang="scss">
    
</style>
