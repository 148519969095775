<template>
    <div id="Login">
        <div class="w-full">
            <div class="logo mb-5">
                <img src="@/assets/hr.png" width="100px" alt="">
            </div>
            <div class="flex items-center justify-center">
                <div class="text-center font-semibold text-2xl">
                    Welcome back to HRMS
                </div>
            </div>
            <p class="text-center text-gray-600 mt-2">Please login to continue</p>
            <van-form @submit="onSubmit" class="mt-4">
                <van-cell-group inset>
                    <van-field
                        v-model="empCode"
                        name="empCode"
                        label="Employee"
                        placeholder="Employee ID"
                        :rules="[{ required: true, message: 'Employee Id is required' }]"
                    />
                    <van-field
                        v-model="companycode"
                        name="companycode"
                        label="Company"
                        placeholder="Company Code"
                        :rules="[{ required: true, message: 'Company Code is required' }]"
                    />
                    <van-field
                        v-model="password"
                        type="password"
                        name="Password"
                        label="Password"
                        placeholder="Password"
                        :rules="[{ required: true, message: 'Password is required' }]"
                    />
                </van-cell-group>
                <div style="margin: 16px;">
                    <van-button round block type="primary" native-type="submit" @click="login">
                        Login
                    </van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            empId: '',
            empCode: '',
            companycode: '',
            password: ''
        }
    },
    methods: {
        async login() {
            this.$router.push("/dashboard");
        }
    },
}
</script>

<style scoped lang="scss">
    #Login {
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        .logo {
            display: flex;
            justify-content: center;
        }
    }   
</style>