import { createApp } from "vue";
import App from "./App.vue";
import "@fortawesome/fontawesome-free/css/all.css";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "./assets/tailwind.css";
import "vant/lib/index.css";
import { Locale } from "vant";
import enUS from "vant/lib/locale/lang/en-US";
import { plugin as formKitPlugin, defaultConfig } from "@formkit/vue";
import { createMultiStepPlugin } from "@formkit/addons";
import "@formkit/themes/genesis";
import "@formkit/addons/css/multistep";
import { DropdownMenu, DropdownItem } from "vant";
import Chakra, { CThemeProvider } from '@chakra-ui/vue'
import AvatarCropper from "vue-avatar-cropper";
// import "vue-croppa/dist/vue-croppa.css";
import Croppa from "vue-croppa";
// or
// const AvatarCropper = require("vue-avatar-cropper");
import {
  Form,
  Field,
  CellGroup,
  Button,
  Overlay,
  Loading,
  Tabbar,
  Tab,
  Tabs,
  TabbarItem,
  ContactList,
  Grid,
  GridItem,
  Icon,
  Calendar,
  Cell,
  Dialog,
  NavBar,
  Uploader,
  Toast,
} from "vant";

const app = createApp(App);
Locale.use("en", enUS);
// app.use(Chakra);
app.use(Form);
app.use(Field);
app.use(CellGroup);
app.use(Button);
app.use(Overlay);
app.use(Loading);
app.use(Tabbar);
app.use(Tab);
app.use(Tabs);
app.use(TabbarItem);
app.use(ContactList);
app.use(Grid);
app.use(GridItem);
app.use(Icon);
app.use(Calendar);
app.use(Cell);
app.use(Dialog);
app.use(NavBar);
app.use(Uploader);
app.use(Toast);
app.use(AvatarCropper);
app.use(Croppa);
app.use(DropdownMenu);
app.use(DropdownItem);
app.use(
  formKitPlugin,
  defaultConfig({
    plugins: [createMultiStepPlugin()],
  })
);

app.use(store).use(router).mount("#app");
