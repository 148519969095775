<template>
  <div>
    <van-nav-bar title="Services" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="search" class="mr-2.5" size="25" />
        <van-icon name="bell" size="25" />
      </template>
    </van-nav-bar>
    <ButtomService />
  </div>
</template>

<script>
import ButtomService from "@/components/Layout/ButtomService.vue";
export default {
  components: {
    ButtomService,
  },
  data() {
    return {};
  },
  methods: {
    onClickLeft() {
      this.$router.push("/dashboard");
    },
    checkAttendance() {
      this.$router.push("/attendance");
    },
    checkLeave() {
      this.$router.push("/leave");
    },
  },
};
</script>

<style scoped lang="scss">
#Services {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;

  .serive-card {
    border-radius: 10px;
    padding: 30px;
    background: #ffffff;
  }

  .box-show-service {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
}
</style>
