<template>
    <div id="Buttom">
        <van-tabbar v-model="active">
            <van-tabbar-item icon="home-o" size="40" @click="goToHome">Home</van-tabbar-item>
            <van-tabbar-item icon="home-o" size="40" @click="goToPilot">Pilot</van-tabbar-item>
            <van-tabbar-item icon="passed" @click="goToData">My Data</van-tabbar-item>
            <van-tabbar-item icon="setting-o" @click="goToService">Services</van-tabbar-item>

            <van-tabbar-item icon="more-o">More</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                active: 0
            }
        },
        methods: {
            async goToService() {
                await this.$nextTick();
                this.active = 3;
                this.$router.push('/service');
            },
            async goToHome() {
                await this.$nextTick();
                this.active = 0;
                this.$router.push('/dashboard');
            },
            async goToPilot() {
                await this.$nextTick();
                this.active = 1;
                this.$router.push('/pilot');
            },
            async goToData() {
                await this.$nextTick();
                this.active = 2;
                this.$router.push('/data');
            }
        }
    }
</script>

<style scoped lang="scss">
</style>
