<template>
  <!-- <head> </head> -->
  <div
    v-if="isLoading"
    style="height: 100vh"
    class="h-full p-11 flex items-center justify-center"
  >
    <div class="wrapper">
      <div class="block">
        <van-loading type="spinner" color="#1989fa" />
      </div>
    </div>
  </div>
  <div v-else>
    <div id="Dashboard" class="relative">
      <div
        v-if="scanning"
        class="fixed z-10 drop-shadow-2xl bottom-[70px] bg-gray-100 rounded-md p-3 right-[50px]"
      >
        <van-loading class="" type="spinner" color="#1989fa">
          <span class="font-bold">{{ ocrProgress }}%</span> Scanning Document.
          feel free to keep working
        </van-loading>
      </div>

      <h3
        class="mt-5 text-2xl font-semibold leading-6 text-gray-900"
        id="modal-title"
      >
        OCR
      </h3>

      <div class="container">
        <div class="variants">
          <!-- <input type="file" accept="" capture="camera" /> -->
          <!-- 
          <div class="file file--upload w-1/2" @click="setupCamera()">
            <label class="w-full">
              <van-icon name="photograph" />
              <span class="text-xs font-normal ml-1" color="#F7F7F7"
                >Take Photo</span
              >
            </label>
          </div>

          <div class="file file--upload w-1/2" @click="captureImage">
            <label class="w-full">
              <van-icon name="enlarge" />
              <span class="text-xs font-normal ml-1" color="#F7F7F7"
                >Capture Photo</span
              >
            </label>
          </div> -->
        </div>
      </div>
      <div class="w-full mt-5 mb-5">
        <video
          v-if="showTakePhoto"
          class="mt-5 rounded-xl"
          ref="video"
          autoplay
        ></video>
        <div v-else></div>
      </div>

      <div class="bg-color-text rounded-xl p-3 mt-5 mb-5 relative">
        <cropper
          ref="ocrCropper"
          v-if="this.displayPhoto"
          :src="this.displayPhoto"
          @change="change"
        />

        <img
          v-else
          class="w-full"
          src="@/assets/placeholder-image.jpeg"
          alt=""
        />
        <div class="absolute flex gap 2 left-5 bottom-5">
          <a
            class="p-1 text-sm text-white rounded-md cursor-pointer hover:opacity-[0.1] transition-all duration-300 bg-blue-500"
            type="checkbox"
            v-if="this.displayPhoto"
            @click="scan"
            >Scan
          </a>
        </div>
        <div class="absolute flex gap-2 right-5 bottom-5">
          <label for="checkbox" class="font-bold text-sm text-blue-600"
            >Black & White
          </label>
          <input
            class="w-5 h-5"
            type="checkbox"
            v-model="checkbox"
            @change="onCheck"
          />
        </div>
      </div>
      <img :src="resizedImage" />
      <!-- <div class="w-full h-full absolute"> -->

      <div class="file file--upload mt-4" @change="loadImage">
        <label for="input-file" class="w-full h-[50px]">
          <img class="object-cover" src="@/assets/upload_image.png" alt="" />
          <span class="text-xs font-normal">Upload or Take Photo</span>
        </label>
        <input
          id="input-file"
          accept="image/x-png,image/jpeg, image/pdf, image/png, image/jpg"
          type="file"
        />
      </div>

      <!-- New -->
      <!-- <div>
                <canvas ref="canvas" :width="pictureWidth" :height="pictureHeight"></canvas>
                <img ref="img" />
            </div> -->
      <div class="upload-btn-wrapper mb-5 mt-5">
        <div class="bg-color-text rounded-xl p-3">
          <van-loading v-if="loading" class="" type="spinner" color="#1989fa">
            <span class="font-bold">{{ ocrProgress }}%</span> Scanning Document.
            feel free to keep working
          </van-loading>
          <div v-else>
            <div v-if="message">
              <div class="flex">
                <span class="font-semibold w-1/2">Result:</span>

                <span
                  class="w-1/2 flex items-center justify-end gap-2"
                  v-if="message"
                >
                  <i
                    class="fa-regular fa-copy"
                    style="
                      background: #fff;
                      padding: 5px;
                      border-radius: 4px;
                      color: gray;
                    "
                    @click="copyText"
                  ></i>
                  <i
                    class="fa-regular fa-pen-to-square cursor-pointer"
                    style="
                      background: #fff;
                      padding: 5px;
                      border-radius: 4px;
                      color: gray;
                    "
                    @click="fillForm"
                  ></i>
                </span>
              </div>

              <textarea
                cols="30"
                rows="10"
                class="bg-color-text w-full mt-5"
                v-model="message"
                ref="message"
              ></textarea>
            </div>
            <div class="flex gap-2" v-else>
              <span class="text-xs font-normal text-gray-600"
                >Upload or Take photo to see result.</span
              >
            </div>
          </div>
        </div>
      </div>
      <!-- <croppa></croppa> -->
      <!-- <croppa v-model="myCroppa" /> -->
      <!-- <avatar-cropper v-model="showCropper" upload-url="/files/upload" /> -->
      <FormKit
        id="myForm"
        type="form"
        :actions="false"
        #default="{ disabled }"
        @submit="saveImages"
      >
        <FormKit type="multi-step" class="bg-blue-500">
          <FormKit type="step" name="contactInformation">
            <!-- collect name, email, and company info -->
            <!-- <button class="" @click="test">Test</button> -->
            <FormKit
              type="text"
              v-model="formData.name"
              label="Name"
              validation="required"
              help="ex: ឡេង​ ប៊ុនហេង"
            />
            <!-- <button @click="fillForm">123</button> -->
            <FormKit
              v-model="formData.nameEn"
              type="text"
              label="Name in English (optional)"
              help="ex: LENG BUNHENG"
            />
            <FormKit
              v-model="formData.phone"
              help="ex: 012 345 123"
              type="text"
              label="Phone Number"
              validation="required"
            />
          </FormKit>

          <FormKit type="step" name="General Info">
            <p class="my-2 font-bold text-sm">Place Type</p>
            <van-dropdown-menu>
              <van-dropdown-item
                @change="HandlePlaceTypeChange"
                v-model="formData.placeType"
                :options="option1"
              />
            </van-dropdown-menu>
            <p class="my-2 font-bold text-sm">Business Type</p>

            <van-dropdown-menu>
              <van-dropdown-item
                :disabled="
                  formData.placeType == 1 || formData.bizType == `Null`
                "
                v-model="formData.bizType"
                :options="option2"
              />
            </van-dropdown-menu>

            <hr class="my-6" />
            <div class="flex">
              <FormKit
                type="text"
                v-model="formData.houseNumber"
                label="House Number (optional)"
              />
              <FormKit
                type="text"
                v-model="formData.streetNumber"
                label="Street Number (optional)"
              />
            </div>

            <FormKit
              type="text"
              v-model="formData.longAddress"
              label="Long Address (optional)"
            />
            <div class="flex">
              <FormKit
                type="text"
                v-model="formData.borey"
                label="Borey (optional)"
              />

              <FormKit
                type="text"
                v-model="formData.landSize"
                label="Land Size (optional)"
              />
            </div>
            <div class="flex">
              <FormKit
                type="text"
                v-model="formData.villageCode"
                label="Village Code (optional)"
              />
              <FormKit
                type="text"
                v-model="formData.communeCode"
                label="Commune Code (optional)"
              />
            </div>

            <FormKit
              type="text"
              v-model="formData.districtCode"
              label="District Code (optional)"
            />
          </FormKit>
          <FormKit type="step" name="Documents">
            <!-- collect name, email, and company info -->
            <FormKit
              type="file"
              multiple
              placeholder="Upload ID Card"
              label="ID
            Card Picture"
              help="To crop the image please click on it."
              accept="image/x-png,image/jpeg, image/pdf, image/png, image/jpg"
              @change="handleFileChangeIDCard"
              :disabled="this.uploading ? `disable` : false"
            />
            <FormKit
              type="text"
              v-model="formData.idCardNumber"
              label="ID Card Number"
              validation="required"
              help="Please upload and double check ID Card Number"
            />
            <div class="flex gap-2 my-2 p-2 overflow-auto">
              <div
                v-for="(image, index) in formData.idCardPicture"
                :key="index"
                class="relative"
              >
                <img
                  @click="cropImage(image, index, 'formData.idCardPicture')"
                  :src="image"
                  style="max-width: 100px; max-height: 100px"
                  alt="Saved Image"
                  class="hover:opacity-[0.7] cursor-pointer border border-black"
                />
                <!-- <displayPhoto>sss</displayPhoto> -->
                <i
                  @click="deleteFromInput(index, 'formData.IdCardPicture')"
                  class="fa-regular cursor-pointer fa-x top-[-5px] px-[5px] py-[5px] bg-red-500 rounded-[50%] text-white right-[-5px] absolute"
                ></i>
              </div>
            </div>

            <hr class="my-3" />
            <FormKit
              type="file"
              multiple
              label="EDC Picture"
              help="To crop the image please click on it."
              accept="image/x-png,image/jpeg, image/pdf, image/png, image/jpg"
              @change="handleFileChangeEDC"
              :disabled="this.uploading ? `disabled` : false"
            />
            <FormKit
              type="text"
              validation="required"
              v-model="formData.edcConsumerID"
              label="EDC Consumer ID"
              help="Please upload and double check Consumer ID"
            />
            <FormKit
              type="text"
              validation="required"
              v-model="formData.edcConsumerName"
              label="EDC Consumer Name"
              help="Please upload and double check Consumer Name"
            />
            <div class="flex gap-2 my-2 p-2 overflow-auto">
              <div
                v-for="(image, index) in formData.edcPicture"
                :key="index"
                class="relative"
              >
                <img
                  :src="image"
                  @click="cropImage(image, index, 'formData.edcPicture')"
                  style="max-width: 100px; max-height: 100px"
                  class="hover:opacity-[0.7] cursor-pointer border border-black"
                  alt="Saved Image"
                />
                <!-- <displayPhoto>sss</displayPhoto> -->
                <i
                  @click="deleteFromInput(index, 'formData.edcPicture')"
                  class="fa-regular cursor-pointer fa-x top-[-5px] px-[5px] py-[5px] bg-red-500 rounded-[50%] text-white right-[-5px] absolute"
                ></i>
              </div>
            </div>
            <hr class="my-3" />
            <FormKit
              type="file"
              multiple
              label="House Picture"
              help="To crop the image please click on it."
              accept="image/x-png,image/jpeg, image/pdf, image/png, image/jpg"
              @change="handleFileChangeHouse"
              :disabled="this.uploading ? `disabled` : false"
            />
            <div class="flex gap-2 my-2 p-2 overflow-auto">
              <div
                v-for="(image, index) in formData.housePicture"
                :key="index"
                class="relative"
              >
                <img
                  :src="image"
                  @click="cropImage(image, index, 'formData.housePicture')"
                  style="max-width: 100px; max-height: 100px"
                  class="hover:opacity-[0.7] cursor-pointer border border-black"
                  alt="Saved Image"
                />
                <!-- <displayPhoto>sss</displayPhoto> -->
                <i
                  @click="deleteFromInput(index, 'formData.housePicture')"
                  class="fa-regular cursor-pointer fa-x top-[-5px] px-[5px] py-[5px] bg-red-500 rounded-[50%] text-white right-[-5px] absolute"
                ></i>
              </div>
            </div>
            <hr class="my-3" />

            <FormKit
              type="file"
              multiple
              accept="image/x-png,image/jpeg, image/pdf, image/png, image/jpg"
              label="Contract Picture"
              help="To crop the image please click on it."
              @change="handleFileChangeContract"
              :disabled="this.uploading ? `disabled` : false"
            />
            <div class="flex gap-2 my-2 p-2 overflow-auto">
              <div
                v-for="(image, index) in formData.contractPicture"
                :key="index"
                class="relative w-full"
              >
                <img
                  @click="cropImage(image, index, 'formData.contractPicture')"
                  :src="image"
                  style="max-width: 100px; max-height: 100px"
                  class="hover:opacity-[0.7] cursor-pointer border border-black"
                  alt="Saved Image"
                />
                <!-- <displayPhoto>sss</displayPhoto> -->
                <i
                  @click="deleteFromInput(index, 'formData.contractPicture')"
                  class="fa-regular cursor-pointer fa-x text-xs fa-2xs top-[-5px] px-[5px] py-[5px] bg-red-500 rounded-[50%] text-white right-[-5px] absolute"
                ></i>
              </div>
            </div>

            <template #stepNext>
              <FormKit type="submit" :disabled="disabled" />
            </template>
          </FormKit>
          <!-- <div v-if="ocrProgress">OCR Progress: {{ ocrProgress }}%</div> -->

          <!-- <FormKit type="step" name="talkDetails">
            Get talk title, brief, and track
            <FormKit type="text" label="Talk Title" validation="required" />
            <FormKit
              type="textarea"
              label="Talk Brief"
              validation="required|length:100"
              help="What is talk about?"
            />
            <FormKit
              type="radio"
              label="Talk Track"
              help="Which track are you submitting for?"
              :options="['Development', 'Testing', 'Leadership']"
            />
          </FormKit> -->

          <!-- <FormKit type="step" name="referral">
            Ask the user to share how they heard about us
            <h2>Thank you for taking the time to submit talk</h2>
            <p>
              If you don't mind we'd love to know how you heard about
              VueUniverse.
            </p>
            <FormKit
              type="radio"
              label="How did you hear about VueUniverse?"
              :options="[
                'Online Ad',
                'Friend or Coworker',
                'Search Results',
                'Other',
              ]"
            />
          </FormKit> -->
        </FormKit>
        <!-- <img :src="formData.idCardPicture" /> -->
        <!-- <div v-for="(images, index) in savedImageData" :key="index">
          <img :src="images.image" alt="Saved Image" />
        </div> -->

        <!-- <img :src="formData.idCardpicture[1]" /> -->
        <!-- <img :src="formData.idCardpicture[2]" /> -->

        <div v-if="!this.syncingData">
          <a
            @click="syncWithDatabase"
            class="bg-blue-500 py-2 px-3 mt-5 text-white rounded-md"
          >
            Sync with Database
          </a>
        </div>
        <div v-else>
          <button
            disabled
            class="bg-blue-500 py-2 px-3 mt-5 text-white rounded-md"
          >
            <van-loading size="24px" class="text-white"
              >Syncing with Database</van-loading
            >
          </button>
        </div>
        <!-- </FormKit -->
      </FormKit>

      <ButtomService />
      <div
        v-if="uploading"
        class="absolute z-2 top-0 w-full flex justify-center h-full right-0 bg-black opacity-[0.6]"
      ></div>
      <div
        v-if="uploading"
        class="bg-white absolute pb-13 top-0 left-0 right-0 z-3 flex justify-center flex-col mx-auto w-[80%] h-[100%] overflow-y-auto"
      >
        <i
          @click="closeCropImage"
          class="fa-regular cursor-pointer fa-x z-11 rounded-[50%] mb-[30px] mr-[30px] text-black text-2xl text-right"
        ></i>

        <div class="flex justify-center">
          <cropper
            ref="cropper"
            :src="this.cropOrUploadPicture"
            class="max-w-[500px] max-h-[500px]"
            @change="actualCrop"
          />
        </div>

        <div class="flex z-10 justify-center items-center gap-2 mt-3">
          <button
            @click="confirmCrop"
            class="bg-blue-500 hover:bg-blue-200 py-2 px-5 text-white rounded-md"
          >
            Crop
          </button>
          <!-- <button class="bg-green-500 p-2 text-white rounded-md">Ok</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import 'glfx';
import axios from "axios";
import Tesseract from "tesseract.js";
import { showDialog, showToast } from "vant";
import ButtomService from "@/components/Layout/ButtomService.vue";
import { split } from "split-khmer";
import { FormKit } from "@formkit/vue";
// import { Vue3MultiStepper } from "vue3-multi-stepper";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import { ref, onMounted } from "vue";
export default {
  components: {
    // Vue3MultiStepper,
    Cropper,
    ButtomService,
  },
  // name: "App",
  data() {
    return {
      resizedImage: null,
      scanning: false,
      formData: {
        name: "",
        nameEn: "",
        phone: "",
        idCardNumber: "",
        placeType: ref(1),
        houseNumber: "",
        streetNumber: "",
        longAddress: "",
        borey: "",
        bizType: ref("Null"),
        bizName: "",
        landSize: "",
        villageCode: "",
        communeCode: "",
        districtCode: "",
        latitude: "",
        longitude: "",
        idCardPicture: [],
        housePicture: [],
        edcPicture: [],
        contractPicture: [],
        edcConsumerID: "",
        edcConsumerName: "",
      },
      data: [],
      ocrProgress: null,
      values: 0,
      showDialog: false,
      isLoading: false,
      showTakePhoto: false,
      displayPhoto: null,
      message: "",
      loading: false,
      zoomLevel: 1,
      images: [],
      db: null,
      dbName: "myDatabase",
      objectStoreName: "images",
      imageData: [],
      savedImageData: [],
      checkboxValue: 0,
      banerizedImage: null,
      originalImage: null,
      documentEdcData: [],
      syncingData: false,
      cropOrUploadPicture: "",
      cropOrUploadIndex: "",
      cropOrUploadFormDataPicture: "",
      croppedImage: null,
      uploading: false,
      roi: { x: 0, y: 0, width: 300, height: 300 },

      // pictureWidth: 300, // Replace with desired width
      // pictureHeight: 200, // Replace with desired height
    };
  },
  setup() {
    const placeType = ref([]);

    const option1 = ref([]);
    const option2 = ref([]);

    // Function to fetch place types
    const fetchPlaceType = async () => {
      try {
        const response = await axios.get(
          "https://pwaocrapi.dpdatacenter.com/api/place-type"
        );
        placeType.value = response.data;

        // Generate options for select input based on fetched place types
        option1.value = placeType.value.map((element) => ({
          text: element.name,
          value: element.id,
        }));

        console.log(placeType.value);
      } catch (error) {
        console.error("Error fetching place types:", error);
        // Fallback data if fetch fails
        placeType.value = [
          { id: 1, name: 'Non-Business Location' },
          { id: 2, name: 'General Business / Normal Resident' },
          { id: 3, name: 'Club/Sport' },
          { id: 4, name: 'Company Office/National Organization' },
          { id: 5, name: 'Bank/Financial Institutions/Microfinance' },
          { id: 6, name: 'Building/Business Center/Mall' },
          { id: 7, name: 'Tourism' },
          { id: 8, name: 'Hospitality' },
          { id: 9, name: 'Educational Institutions (Autonomous/Private)' },
          { id: 10, name: 'Hospital (Autonomous/Private)' },
          { id: 11, name: 'Station/Fuel gas station/Gas' },
          { id: 12, name: 'Warehouse' },
          { id: 13, name: 'Industry/Production' },
          { id: 14, name: 'Embassy/Consulate/International Organization' },
          { id: 15, name: 'Institutions/State entities' },
          { id: 16, name: 'Other' }
        ];
        
        // Generate options for select input based on fallback data
        option1.value = placeType.value.map((element) => ({
          text: element.name,
          value: element.id,
        }));
      }
    };

    onMounted(fetchPlaceType);

    option2.value = [{ text: "Select Place Type", value: "Null" }];
    return {
      option1,
      option2,
    };
  },
  methods: {
    HandlePlaceTypeChange() {
      console.log(this.formData.bizType);
      console.log(this.formData.placeType);
      const businessType = ref([]);
      axios
        .get(
          `https://pwaocrapi.dpdatacenter.com/api/business-type-match/${this.formData.placeType}`
        )
        .then((response) => {
          console.log(response.data);
          businessType.value = response.data;
          if (businessType.value == []) {
            this.option2 = [{ text: "No Business type found", value: 0 }];
          } else {
            this.option2 = businessType.value.map((element) => ({
              text: element.name,
              value: element.id,
            }));
          }
        
          const val = this.option2[0].value ? this.option2[0].value : "None";
          this.formData.bizType = val;
        })
        .catch((error) => {
          console.error("Error fetching business types:", error);
          const allOptions = [
            { text: "Business/Service/Shop", value: 1, place_type_id: 2 },
            { text: "Restaurant/Food", value: 2, place_type_id: 2 },
            { text: "Bars/Bagaden", value: 3, place_type_id: 3 },
            { text: "KTV/Festival/Cinema", value: 4, place_type_id: 3 },
            { text: "LotteryCasino", value: 5, place_type_id: 3 },
            { text: "Fitness Club", value: 6, place_type_id: 3 },
            { text: "Airport", value: 7, place_type_id: 7 },
            { text: "Train station", value: 8, place_type_id: 7 },
            { text: "Transport station", value: 9, place_type_id: 7 },
            { text: "Inland water transport", value: 10, place_type_id: 7 },
            { text: "Hotel has no park(Under three stars)/Guesthouse", value: 11, place_type_id: 8 },
            { text: "4-star hotel", value: 12, place_type_id: 8 },
            { text: "5-star hotel", value: 13, place_type_id: 8 },
            { text: "Higher education institutions - International", value: 14, place_type_id: 9 },
            { text: "Higher education institutions - National", value: 15, place_type_id: 9 },
            { text: "General Knowledge Institute - International", value: 16, place_type_id: 9 },
            { text: "General Knowledge Institute - National", value: 17, place_type_id: 9 },
            { text: "Vocational Training Institute", value: 18, place_type_id: 9 },
            { text: "Hospital/Polyclinic", value: 19, place_type_id: 10 },
            { text: "Clinic/Dentist", value: 20, place_type_id: 10 },
            { text: "Station/Fuel gas station/Gas(No business)", value: 21, place_type_id: 11 },
            { text: "Station/Fuel gas station/Gas(Additional business)", value: 22, place_type_id: 11 },
            { text: "Fuel storage tanks/Gas", value: 23, place_type_id: 11 },
            { text: "Warehouse", value: 24, place_type_id: 12 },
            { text: "Autonomous Port/Dry port", value: 25, place_type_id: 12 },
            { text: "Crafts/Light industry", value: 26, place_type_id: 13 },
            { text: "Factory/Heavy industry", value: 27, place_type_id: 13 },
            { text: "Sand Depot/Concrete Mixing Site", value: 28, place_type_id: 16 },
            { text: "Church/Monastery", value: 29, place_type_id: 16 },
            { text: "Irregular market/Cart(Mobile booth)", value: 30, place_type_id: 16 }
          ];
          
          this.option2 = allOptions.filter(option => option.place_type_id === parseInt(this.formData.placeType));
          
          if (this.option2.length === 0) {
            this.option2 = [{ text: "No Business type found", value: 0 }];
          }
          
          this.formData.bizType = this.option2[0].value;
          
          console.log(this.formData.bizType);    
        });
    },
    confirmCrop() {
      const croppedImage = this.$refs.cropper.getResult();
      const real = croppedImage.canvas.toDataURL();

      if (this.cropOrUploadFormDataPicture == "formData.idCardPicture") {
        this.formData.idCardPicture[this.cropOrUploadIndex] = real;
      } else if (this.cropOrUploadFormDataPicture == "formData.edcPicture") {
        this.formData.edcPicture[this.cropOrUploadIndex] = real;
      } else if (this.cropOrUploadFormDataPicture == "formData.housePicture") {
        this.formData.housePicture[this.cropOrUploadIndex] = real;
      } else if (
        this.cropOrUploadFormDataPicture == "formData.contractPicture"
      ) {
        this.formData.contractPicture[this.cropOrUploadIndex] = real;
      }
      this.closeCropImage();
    },
    cropImage(image, index, formDataPicture) {
      this.cropOrUploadPicture = image;
      this.cropOrUploadFormDataPicture = formDataPicture;
      this.cropOrUploadIndex = index;
      // this.disablePageScroll();
      this.uploading = true;
    },
    closeCropImage() {
      // this.enablePageScroll();
      this.cropOrUploadPicture = "";
      this.cropOrUploadFormDataPicture = "";
      this.cropOrUploadIndex = "";
      this.uploading = false;
    },
    scan() {
      const croppedImage = this.$refs.ocrCropper.getResult();
      this.resizedImage = croppedImage.canvas.toDataURL();

      this.extractTextFromImage(this.resizedImage);
    },
    deleteFromInput(index, fieldName) {
      // console.log(fieldName);
      if (fieldName == "formData.IdCardPicture") {
        this.formData.idCardPicture.splice(index, 1);
      } else if (fieldName == "formData.edcPicture") {
        this.formData.edcPicture.splice(index, 1);
      } else if (fieldName == "formData.housePicture") {
        this.formData.housePicture.splice(index, 1);
      } else if (fieldName == "formData.contractPicture") {
        this.formData.contractPicture.splice(index, 1);
      }
    },

    // disablePageScroll() {
    //   // Get the current page scroll position
    //   document.documentElement.style.overflow = "hidden";
    // },

    // enablePageScroll() {
    //   document.documentElement.style.overflow = "auto";
    // },

    async syncWithDatabase() {
      const transactionRead = this.db.transaction(
        [this.objectStoreName],
        "readonly"
      );
      const objectStoreRead = transactionRead.objectStore(this.objectStoreName);
      const countRequestRead = objectStoreRead.count();

      countRequestRead.onsuccess = () => {
        if (countRequestRead.result === 0) {
          showToast("Nothing to sync");
          return;
        }
      };
      countRequestRead.oncomplete = () => {
        this.db.close();
      };

      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readonly"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);

      const getAllRequest = objectStore.getAll();

      getAllRequest.onsuccess = async () => {
        this.data = getAllRequest.result;

        await this.data.forEach((data) => {
          const formData = new FormData();

          // The code is appending the same 'data' value twice to the formData
          // This likely won't send any data since the keys need to be unique
          formData.append("ref_id", data.id);

          // To fix this, we need to append each data field with a unique key
          formData.append("name", data.name);
          formData.append("name_en", data.nameEn);
          formData.append("phones", data.phone);
          formData.append("id_card", data.idCardNumber);
          formData.append("edc_consumer_id", data.edcConsumerID);
          formData.append("edc_consumer_name", data.edcConsumerName);
          formData.append("id_card", data.idCardNumber);
          formData.append("borey", data.borey);
          formData.append("village_code", data.villageCode);
          formData.append("commune_code", data.communeCode);
          formData.append("district_code", data.districtCode);
          formData.append("street_number", data.streetNumber);
          formData.append("house_number", data.houseNumber);
          formData.append("long_address", data.longAddress);
          formData.append("place_type", data.placeType);
          formData.append("biz_type", data.bizType);
          formData.append("biz_name", data.bizName);

          formData.append("land_size", data.landSize);
          formData.append("latitude", data.latitude);
          formData.append("longitude", data.longitude);
          this.syncingData = true;
          axios
            .post("https://pwaocrapi.dpdatacenter.com/api/data", formData, {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then((response) => {
              console.log(response);

              data.documents.forEach((document) => {
                document.forEach((doc) => {
                  console.log(doc);
                
                  doc.forEach((d) => {
                    let newFormData = new FormData();
                    newFormData.append("id_card_number", data.idCardNumber);
                    newFormData.append("file_id", d.file_id);
                    newFormData.append("file_type", d.file_type);
                    newFormData.append("image", d.image);
                    axios
                      .post(
                        "https://pwaocrapi.dpdatacenter.com/api/document/edc",
                        newFormData,
                        {
                          headers: {
                            "Content-Type": "multipart/form-data",
                          },
                        }
                      )
                      .then((response) => {
                        console.log(response);

                        this.syncingData = false;
                      })
                      .catch((error) => {
                        console.log(error);
                        this.syncingData = false;
                      });
                  });
                });
              });
              console.log(response);
              const request = this.db
                .transaction([this.objectStoreName], "readwrite")
                .objectStore(this.objectStoreName)
                .delete(data.id);

              request.onsuccess = () => {
                showToast("Data sync successful");
              };

              request.onerror = (err) => {
                console.error(`Error to Delete from indexedDB`);
              };

              this.syncingData = false;
            })
            .catch((error) => {
              showToast(
                "Data sync error please check your internet or try again later."
              );
              console.log(error);
              this.syncingData = false;
            });
        });
      };
    },

    onCheck() {
      if (this.checkbox) {
        this.checkboxValue = 1;
        if (this.displayPhoto) {
          this.displayPhoto = this.banerizedImage;
          this.extractTextFromImage(this.banerizedImage);
        }
      } else {
        this.checkboxValue = 0;
        if (this.displayPhoto) {
          this.displayPhoto = this.originalImage;
          this.extractTextFromImage(this.originalImage);
        }
      }
    },
    fillForm() {
      // Assuming you have the extracted text stored in a variable named 'extractedText'
      const mappings = {
        name: this.extractField(
          [
            "Name",
            "គៅគ្គនាមនិងនាទ",
            "គៅគ្គនាមនិងនាម",
            "គេគ្គនាទនិងនាទ",
            "គៅគ្គនាមនឹងនាទ",
            "គោគ្គនាមនិងនាម",
          ],
          this.message
        ),
        nameEn: this.extractField(
          ["Name in English", "nameen", "name in eng", "english name"],
          this.message
        ),
        phone: this.extractField(
          ["Phone Number", "លេខទូរស័ព្ទ", "phone", "phones"],
          this.message
        ),
        idCardNumber: this.extractField(
          [
            "ID Card Number",
            "លេខអត្តសញ្ញាណប័ណ្ណ",
            "~~",
            "និ",
            "id",
            "id card",
            "card",
            "idcardnumber",
            "id card",
          ],
          this.message
        ),
        placeType: this.extractField(
          ["Place Type", "ប្រភេទទីកន្លែង"],
          this.message
        ),
        houseNumber: this.extractField(
          ["House Number", "លេខផ្ទះ"],
          this.message
        ),
        streetNumber: this.extractField(
          ["Street Number", "លេខផ្លូវ"],
          this.message
        ),
        longAddress: this.extractField(
          [
            "Long Address",
            "អាសយដ្ឋានវ៉ិចទ័រ",
            "អាសឃដ្វាន",
            "អាសយដ្ឋាន",
            "អាសយដ្ឋានៈ",
            "អាសយដ្ធាន",
            "_ អាសយដ្ឋាន",
            "អាសមដ្ឋាន",
            "អាសយដ្ឋានៈ",
            "អាសយដ្ឋាន",
          ],
          this.message
        ),
        borey: this.extractField(["Borey", "បរិយាយដល់", "បុរី"], this.message),

        bizType: this.extractField(
          ["Biz Type", "ប្រភេទអាជីវកម្ម"],
          this.message
        ),
        landSize: this.extractField(["Land Size", "ទំហំដី"], this.message),
        villageCode: this.extractField(
          ["Village Code", "លេខកូដភូមិ"],
          this.message
        ),
        communeCode: this.extractField(
          ["Commune Code", "លេខកូដសង្កាត់"],
          this.message
        ),
        districtCode: this.extractField(
          ["District Code", "លេខកូដស្រុក"],
          this.message
        ),
        edcConsumerID: this.extractField(
          [
            "លេខសំគាល់អតិថិជន",
            "លេខសំគាល់អតិថិជន |០",
            "លេខសម្គាល់អតិថិជន ID",
            "លេខសំគាល់អតិថិជន ID",
          ],
          this.message
        ),

        // email: this.extractField("Email", extractedText),
        // Add more mappings for other input fields as needed
      };

      // Assign extracted sections to corresponding form fields
      for (let fieldName in mappings) {
        this.formData[fieldName] = mappings[fieldName];
      }
      showToast("Form filled");
    },
    extractField(fieldNames, text) {
      const extractedFields = {};

      // Iterate over each field name
      fieldNames.forEach((fieldName) => {
        // Construct a regular expression pattern to match the field name and its value
        // const regex = new RegExp(
        //   `${fieldName.replace(/\s+/g, "\\s*")}:\\s*(.+)`,
        //   "i"
        // );
        const regex = new RegExp(
          `${fieldName.replace(/\s+/g, "\\s*")}\\s*:?\\s*(?:\\|០\\s*)?(.+)`,
          "i"
        );
        // Search for the line containing the field name (case-insensitive)
        const fieldLine = text.match(regex);

        if (fieldLine) {
          console.log(fieldLine);
          let fieldValue = fieldLine;
          // Extract the field value and trim whitespace
          if (fieldLine[1]) {
            fieldValue = fieldLine[1].trim();
          } else {
            fieldValue = "";
          }

          extractedFields[fieldName] = fieldValue; // Add the field value to the extractedFields object
        } else {
          extractedFields[fieldName] = ""; // Add an empty string if field not found
        }
      });

      const extractedNonEmptyFields = {};

      for (let fieldName in extractedFields) {
        // console.log(fieldName);
        if (extractedFields[fieldName] !== "") {
          extractedNonEmptyFields[fieldName] = extractedFields[fieldName];
          // console.log(extractedFields[fieldName]);
          // To extract the 123 value:
          const nameValue = extractedNonEmptyFields[fieldName];

          return nameValue;
        }
      }
    },
    extractConsumerNameField(fieldNames, text) {
      const extractedFields = {};

      // Iterate over each field name
      fieldNames.forEach((fieldName) => {
        // Construct a regular expression pattern to match the field name and its value
        // const regex = new RegExp(
        //   `${fieldName.replace(/\s+/g, "\\s*")}:\\s*(.+)`,
        //   "i"
        // );
        const regex = new RegExp(
          `(?<!លេខសំគាល់)${fieldName.replace(
            /\s+/g,
            "\\s*"
          )}\\s*:?\\s*(?:\\|០\\s*)?(.+)`,
          "i"
        );
        // Search for the line containing the field name (case-insensitive)
        const fieldLine = text.match(regex);

        if (fieldLine) {
          console.log(fieldLine);
          let fieldValue = fieldLine;
          // Extract the field value and trim whitespace
          if (fieldLine[1]) {
            fieldValue = fieldLine[1].trim();
          } else {
            fieldValue = "";
          }

          extractedFields[fieldName] = fieldValue; // Add the field value to the extractedFields object
        } else {
          extractedFields[fieldName] = ""; // Add an empty string if field not found
        }
      });

      const extractedNonEmptyFields = {};

      for (let fieldName in extractedFields) {
        // console.log(fieldName);
        if (extractedFields[fieldName] !== "") {
          extractedNonEmptyFields[fieldName] = extractedFields[fieldName];
          // console.log(extractedFields[fieldName]);
          // To extract the 123 value:
          const nameValue = extractedNonEmptyFields[fieldName];

          return nameValue;
        }
      }
    },
    extractIDField(fieldNames, text) {
      const extractedFields = {};

      // Iterate over each field name
      fieldNames.forEach((fieldName) => {
        // Construct a regular expression pattern to match the field name and its value
        // const regex = new RegExp(
        //   `${fieldName.replace(/\s+/g, "\\s*")}:\\s*(.+)`,
        //   "i"
        // );
        const regex = new RegExp(
          `${fieldName.replace(/\s+/g, "\\s*")}\\s*:?\\s*([^<]+)`,
          "i"
        );

        // Search for the line containing the field name (case-insensitive)
        const fieldLine = text.match(regex);

        if (fieldLine) {
          console.log(fieldLine);
          let fieldValue = fieldLine;
          // Extract the field value and trim whitespace
          if (fieldLine[1]) {
            fieldValue = fieldLine[1].trim();
          } else {
            fieldValue = "";
          }

          extractedFields[fieldName] = fieldValue; // Add the field value to the extractedFields object
        } else {
          extractedFields[fieldName] = ""; // Add an empty string if field not found
        }
      });
      const extractedNonEmptyFields = {};

      for (let fieldName in extractedFields) {
        // console.log(fieldName);
        if (extractedFields[fieldName] !== "") {
          extractedNonEmptyFields[fieldName] = extractedFields[fieldName];
          // console.log(extractedFields[fieldName]);
          // To extract the 123 value:
          const nameValue = extractedNonEmptyFields[fieldName];

          return nameValue;
        }
      }
    },
    handleFileChange(event) {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        const reader = new FileReader();
        reader.onload = () => {
          this.images.push(reader.result);
        };
        reader.readAsDataURL(files[i]);
      }
    },
    async handleFileChangeIDCard(event) {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 10485760) {
          showToast("file too big");
          return;
        }
        const reader = new FileReader();

        reader.onload = async () => {
          const imageData = await reader.result;
          this.scanning = true;
          const extractedText = await this.extractIDTextFromImage(imageData);
          // console.log(extractedText);
          const extractedID = await this.extractIDField(
            ["IDKHM"],
            extractedText
          );
          // console.log(extractedID);
          this.scanning = false;
          this.formData.idCardNumber = await extractedID;
          if (this.formData.idCardNumber) {
            showToast(
              " ID Detected & Auto Filled: " + this.formData.idCardNumber
            );
          } else {
            showToast(" ID not detected");
          }

          this.formData.idCardPicture.push(reader.result);
        };
        await reader.readAsDataURL(files[i]);
      }
    },
    handleFileChangeHouse(event) {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 10485760) {
          showToast("file too big");
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          this.formData.housePicture.push(reader.result);
        };
        reader.readAsDataURL(files[i]);
      }
    },
    handleFileChangeEDC(event) {
      const files = event.target.files;

      if (!files || files.length === 0) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 10485760) {
          showToast("file too big");
          return;
        }
        const reader = new FileReader();
        reader.onload = async () => {
          const imageData = await reader.result;
          this.scanning = true;
          const extractedText = await this.extractEDCTextFromImage(imageData);
          // console.log(extractedText);
          const extractedConsumerID = await this.extractField(
            [
              "លេខសំគាល់អតិថិជន",
              "លេខសំគាល់អតិថិជន |០",
              "លេខសម្គាល់អតិថិជន ID",
              "លេខសំគាល់អតិថិជន ID",
            ],
            extractedText
          );
          const extractedConsumerName = await this.extractField(
            [
              "ឈ្មោះអតិថិជន",
              // "អតិថិជន",

              "ATs",

              // "អាសយដ្ឋានទទួលវិក្កយបត្រ I EY",
              // "អាសយដ្ឋានទទួលវិក្កយបត្រ",
            ],
            extractedText
          );

          // console.log(extractedConsumerID);
          this.scanning = false;
          this.formData.edcConsumerID = await extractedConsumerID;
          this.formData.edcConsumerName = await extractedConsumerName;
          if (this.formData.edcConsumerID) {
            showToast(
              "Consumer ID Detected & Auto Filled: " +
                this.formData.edcConsumerID
            );
          } else {
            showToast("Consumer ID not detected");
          }
          if (this.formData.edcConsumerName) {
            showToast(
              "Consumer Name Detected & Auto Filled: " +
                this.formData.edcConsumerName
            );
          } else {
            showToast("Looking for Consumer Name");
            this.scanning = true;
            const img = new Image();
            img.src = imageData;
            const desiredWidth = 300;
            const desiredHeight = 300;
            img.onload = async () => {
              // Create a canvas element
              const canvas = document.createElement("canvas");
              const ctx = canvas.getContext("2d");

              // Set the canvas dimensions to the desired width and height
              canvas.width = desiredWidth;
              canvas.height = desiredHeight;

              // Draw the image onto the canvas with the desired dimensions and starting from the bottom-left corner
              ctx.drawImage(
                img,
                0,
                img.height - desiredHeight,
                desiredWidth,
                desiredHeight,
                0,
                0,
                desiredWidth,
                desiredHeight
              );

              // Get the resized image data from the canvas
              const resizedImageData = canvas.toDataURL("image/jpeg");

              // Now you can pass the resizedImageData to your extraction function
              const extractedText = await this.extractEDCTextFromImage(
                resizedImageData
              );
              const extractedConsumerName = await this.extractConsumerNameField(
                [
                  "ឈ្មោះអតិថិជន",

                  "ATs",
                  "អតិថិជន",

                  // "អាសយដ្ឋានទទួលវិក្កយបត្រ I EY",
                  // "អាសយដ្ឋានទទួលវិក្កយបត្រ",
                ],
                extractedText
              );
              this.scanning = false;
              this.formData.edcConsumerName = await extractedConsumerName;

              // console.log(extractedText);
            };
          }
          this.formData.edcPicture.push(reader.result);
        };
        reader.readAsDataURL(files[i]);
      }
    },
    handleFileChangeContract(event) {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 10485760) {
          showToast("file too big");
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          this.formData.contractPicture.push(reader.result);
        };
        reader.readAsDataURL(files[i]);
      }
    },
    saveImages() {
      // if (!this.images || this.images.length === 0) {
      //   showToast("No images selected");
      //   return;
      // }
      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readwrite"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);

      // Handle the error from trying to add an array to the object store

      const documents = [
        [
          this.formData.idCardPicture.map((image) => ({
            file_id: 2,
            file_type: "ID_Card_Pic",
            image: image, // Assuming `image` is a data URL or Blob
          })),
        ],
        [
          this.formData.housePicture.map((image) => ({
            file_id: 2,
            file_type: "House_Pic",

            image: image, // Assuming `image` is a data URL or Blob
          })),
        ],
        [
          this.formData.edcPicture.map((image) => ({
            file_id: 2,
            file_type: "EDC_Pic",

            image: image, // Assuming `image` is a data URL or Blob
          })),
        ],
        [
          this.formData.contractPicture.map((image) => ({
            file_id: 2,
            file_type: "Contract_Pic",
            image: image, // Assuming `image` is a data URL or Blob
          })),
        ],
      ];

      // Convert the array properties to strings before adding to object store
      const data = {
        name: this.formData.name,
        nameEn: this.formData.nameEn,
        phone: this.formData.phone,
        idCardNumber: this.formData.idCardNumber,
        placeType: this.formData.placeType,
        houseNumber: this.formData.houseNumber,
        streetNumber: this.formData.streetNumber,
        longAddress: this.formData.longAddress,
        borey: this.formData.borey,
        bizType: this.formData.bizType,
        placeType: this.formData.placeType,
        landSize: this.formData.landSize,
        villageCode: this.formData.villageCode,
        communeCode: this.formData.communeCode,
        districtCode: this.formData.districtCode,
        documents: documents,
        edcConsumerID: this.formData.edcConsumerID,
        edcConsumerName: this.formData.edcConsumerName,
        // idCardData: idCardData,
        // edcData: edcData,
        // houseData: houseData,
        // contractData: contractData,

        createdAt: new Date(),
      };

      const request = objectStore.add(data);

      request.onsuccess = () => {
        console.log("Image saved successfully");

        showToast("Submitted successfully");
        this.$formkit.reset("myForm");
        this.$formkit.clearErrors();
        // this.$formkit.get("Contact Information");

        this.formData.idCardPicture = [];
        this.formData.edcPicture = [];
        this.formData.housePicture = [];
        this.formData.contractPicture = [];
      };

      request.onerror = () => {
        console.error("Error saving image");
      };
    },
    retrieveImages() {
      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readonly"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);
      const request = objectStore.getAll();

      request.onsuccess = () => {
        this.savedImageData = request.result;

        // this.savedImageData = request.result.map((item) => item.images);
      };

      request.onerror = () => {
        console.error("Error retrieving images");
      };
    },
    async extractTextFromImage(image) {
      this.loading = true;
      try {
        // Tesseract.WorkerOptions = {
        //   // workerPath: '/path/to/tesseract.js/dist/worker.min.js',
        //   langPath: "src/trainneddata",
        // };
        // const languages = ;
        const {
          data: { text },
        } = await Tesseract.recognize(image, ["eng", "khm"], {
          langPath: "/train",
          logger: (m) => {
            if (m) {
              // console.log(m.status);
              if (m.status == "recognizing text") {
                this.ocrProgress = Math.round(m.progress * 100);
              }
              // Extract progress from message
            }
          },
        });

        this.message = text;

        // this.fillForm();
      } catch (error) {
        console.error("Error extracting text from images:", error);
      } finally {
        this.loading = false;
      }
    },
    async extractEDCTextFromImage(image) {
      this.loading = true;
      try {
        let progress = 0;
        // Tesseract.WorkerOptions = {
        //   // workerPath: '/path/to/tesseract.js/dist/worker.min.js',
        //   langPath: "src/trainneddata",
        // };
        // const languages = ;
        const {
          data: { text },
        } = await Tesseract.recognize(image, ["eng", "khm"], {
          langPath: "/train",
          logger: (m) => {
            if (m) {
              // console.log(m.status);
              if (m.status == "recognizing text") {
                this.ocrProgress = Math.round(m.progress * 100);
              }
              // Extract progress from message
            }
          },
        });
        return text;
        // console.log(split(this.message));
        // this.fillForm();
      } catch (error) {
        console.error("Error extracting text from images:", error);
      } finally {
        this.loading = false;
      }
    },
    async extractIDTextFromImage(image) {
      this.loading = true;
      try {
        let progress = 0;
        // Tesseract.WorkerOptions = {
        //   // workerPath: '/path/to/tesseract.js/dist/worker.min.js',
        //   langPath: "src/trainneddata",
        // };
        // const languages = ;
        const {
          data: { text },
        } = await Tesseract.recognize(image, ["eng"], {
          langPath: "/train",
          logger: (m) => {
            if (m) {
              // console.log(m.status);
              if (m.status == "recognizing text") {
                this.ocrProgress = Math.round(m.progress * 100);
              }
              // Extract progress from message
            }
          },
        });
        return text;
        // console.log(split(this.message));
        // this.fillForm();
      } catch (error) {
        console.error("Error extracting text from images:", error);
      } finally {
        this.loading = false;
      }
    },

    async loadImage(event) {
      const file = event.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = async () => {
        const image = new Image();
        image.src = reader.result;
        // this.convertToBlackAndWhite(this.displayPhoto);
        image.onload = async () => {
          this.banerizedImage = await this.binarizeImage(image);
          this.originalImage = reader.result;
          // console.log(this.originalImage);
          if (this.checkboxValue == 1) {
            const binarizedImage = await this.binarizeImage(image);
            this.displayPhoto = binarizedImage;
            await this.extractTextFromImage(binarizedImage);
          } else {
            this.displayPhoto = reader.result;

            await this.extractTextFromImage(this.displayPhoto);
          }
        };
      };
    },
    async binarizeImage(image) {
      return new Promise((resolve) => {
        // Create a canvas element to perform image processing
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");

        // Set canvas dimensions to match the image
        canvas.width = image.width;
        canvas.height = image.height;

        // Draw the image on the canvas
        ctx.drawImage(image, 0, 0);

        // Convert the image to grayscale
        const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        for (let i = 0; i < data.length; i += 4) {
          const grayscale = (data[i] + data[i + 1] + data[i + 2]) / 3;
          data[i] = grayscale; // Red channel
          data[i + 1] = grayscale; // Green channel
          data[i + 2] = grayscale; // Blue channel
        }
        ctx.putImageData(imageData, 0, 0);

        // Binarization (thresholding)
        const threshold = 127; // Threshold value (adjust as needed)
        for (let i = 0; i < data.length; i += 4) {
          const grayscale = data[i]; // Use red channel as grayscale
          const binary = grayscale > threshold ? 255 : 0; // Binary thresholding
          data[i] = binary; // Red channel
          data[i + 1] = binary; // Green channel
          data[i + 2] = binary; // Blue channel
        }
        ctx.putImageData(imageData, 0, 0);

        // Convert canvas to base64 data URL
        const binarizedImage = canvas.toDataURL("image/jpeg");
        resolve(binarizedImage);
      });
    },
    copyText() {
      const element = this.$refs.message;
      element.select();
      element.setSelectionRange(0, 99999); // For mobile devices
      document.execCommand("copy");
      showToast("Copied!");
    },

    // convertToBlackAndWhite(imageURL, canvas) {
    //   const ctx = canvas.getContext("2d");
    //   const img = new Image();
    //   img.src = imageURL;
    //   img.onload = function () {
    //     ctx.drawImage(img, 0, 0);
    //     const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    //     const data = imageData.data;
    //     console.log(data);
    //     for (let i = 0; i < data.length; i += 4) {
    //       const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
    //       data[i] = avg; // Red
    //       data[i + 1] = avg; // Green
    //       data[i + 2] = avg; // Blue
    //     }

    //     ctx.putImageData(imageData, 0, 0);
    //   };
    // },
    // zoomImage(event) {
    //   event.preventDefault();
    //   const images = this.$refs.imageRef;
    //   this.zoomLevel += event.deltaY * -0.01;
    //   image.style.transform = `scale(${this.zoomLevel})`;
    // },
  },
  created() {},
  mounted() {
    const request = window.indexedDB.open(this.dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      db.createObjectStore(this.objectStoreName, {
        keyPath: "id",
        autoIncrement: true,
      });
    };

    request.onsuccess = (event) => {
      this.db = event.target.result;
      this.retrieveImages();
    };
  },
};
</script>

<style scoped lang="scss">
#Dashboard {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;
}
.bg-color-text {
  background: #f7f7f7;
}
</style>
