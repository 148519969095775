<template>
  <div>
    <van-nav-bar title="PILOT COLLECTION" left-arrow @click-left="onClickLeft">
      <template #right>
        <van-icon name="search" class="mr-2.5" size="25" />
        <van-icon name="bell" size="25" />
      </template>
    </van-nav-bar>
    <div class="flex flex-col items-center mt-4 p-4">
      <!-- <h1>Search with EDC ID</h1> -->
      <div class="flex gap-2 items-center">
        <FormKit
          type="search"
          placeholder="Search..."
          label="Search with EDC ID"
          v-model="searchValue"
        />
        <button @click="handleSearch()" class="p-2 bg-blue-500 rounded-md mt-2">
          <i class="fa-solid fa-magnifying-glass text-white"></i>
        </button>
      </div>
      <!-- <hr> -->
      <div class="mt-10"></div>

      <FormKit
        id="myForm"
        type="form"
        :disabled="!formData.edc_id"
        @submit="saveToLocal"
        :value="data"
      >
        <div class="w-full">
          <FormKit
            type="text"
            label="EDC ID"
            v-model="formData.edc_id"
            disabled
            placeholder="EDC ID"
          />
        </div>

        <div class="flex gap-2">
          <FormKit
            type="text"
            label="Name"
            v-model="formData.name"
            placeholder="Name in Khmer"
          />
          <FormKit
            type="text"
            label="Name in English"
            v-model="formData.name_en"
            placeholder="Name in English"
          />
        </div>
        <div class="flex gap-2">
          <FormKit
            type="text"
            label="House Number"
            v-model="formData.house_number"
            placeholder="House Number"
          />
          <FormKit
            type="text"
            label="Street Number"
            v-model="formData.street_number"
            placeholder="Street Number"
          />
        </div>
        <div class="flex gap-2">
          <FormKit
            type="text"
            label="Village Code"
            v-model="formData.village_code"
            placeholder="Village Code"
          />
          <FormKit
            type="text"
            label="Commune Code"
            v-model="formData.commune_code"
            placeholder="Commune Code"
          />
        </div>
        <div class="flex gap-2">
          <FormKit
            type="text"
            label="District"
            v-model="formData.district_code"
            placeholder="District"
          />
          <FormKit
            type="text"
            label="City"
            placeholder="City"
            v-model="formData.city"
          />
        </div>
        <div class="w-full">
          <FormKit
            type="file"
            label="Location Photo"
            name="location_photo"
            multiple
            @change="handleLocationPhoto"
          />
        </div>

        <div class="flex gap-2">
          <FormKit
            type="text"
            label="Latitude"
            v-model="formData.latitude"
            placeholder="Latitude"
          />
          <FormKit
            type="text"
            label="Longtitude"
            v-model="formData.longitude"
            placeholder="Longtitude"
          />
        </div>
      </FormKit>
      <div class="pb-12" style="padding-bottom: 50px">
        <a
          @click="syncWithDatabase"
          class="bg-blue-600 py-2 px-3 mt-5 text-white rounded-md"
        >
          Sync with Database
        </a>
      </div>
    </div>

    <ButtomService />
  </div>
</template>

<script>
import axios from "axios";
import { showDialog, showToast } from "vant";

import ButtomService from "@/components/Layout/ButtomService.vue";
export default {
  components: {
    ButtomService,
  },
  data() {
    return {
      searchValue: "",
      db: null,
      dbName: "myLocalPilotDB",
      formData: {
        edc_id: "",
        name: "",
        name_en: "",
        house_number: "",
        street_number: "",
        village_code: "",
        commune_code: "",
        district_code: "",
        city: "",
        latitude: "",
        longitude: "",
        location_photo: [],
      },
      objectStoreName: "localPilot",
      searchResults: [],
      data: [], // Add this line to create an array for search results
    };
  },
  created() {
    this.initIndexedDB();
  },
  methods: {
    initIndexedDB() {
      const request = indexedDB.open(this.dbName, 1);

      request.onerror = (event) => {
        console.error("IndexedDB error:", event.target.error);
      };

      request.onsuccess = (event) => {
        this.db = event.target.result;
        showToast("IndexedDB initialized successfully");
      };

      request.onupgradeneeded = (event) => {
        const db = event.target.result;
        db.createObjectStore(this.objectStoreName, { keyPath: "edc_id" });
      };
    },
    saveToLocal() {
      if (!this.db) {
        console.error("IndexedDB is not initialized");
        // Handle the error, maybe show a message to the user
        return;
      }

      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readwrite"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);

      const documents = [
        this.formData.location_photo.map((image) => ({
          id_card_number: "pilot",
          file_id: 5,
          edc_consumer_id: this.formData.edc_id,
          file_type: "location_photo",
          image: image, // Assuming `image` is a data URL or Blob
        })),
      ];

      const data = {
        edc_id: this.formData.edc_id,
        name: this.formData.name,
        name_en: this.formData.name_en,
        house_number: this.formData.house_number,
        street_number: this.formData.street_number,
        village_code: this.formData.village_code,
        commune_code: this.formData.commune_code,
        district_code: this.formData.district_code,
        city: this.formData.city,
        latitude: this.formData.latitude,
        longitude: this.formData.longitude,
        location_photo: documents,
      };

      const getRequest = objectStore.get(this.formData.edc_id);

      getRequest.onsuccess = (event) => {
        if (event.target.result) {
          // If the record exists, update it
          const updateRequest = objectStore.put(data);
          updateRequest.onsuccess = () => {
            showToast("Updated");
            this.clearFormData();
          };
          updateRequest.onerror = () => {
            console.error("Error updating data");
          };
        } else {
          // If the record doesn't exist, add it
          const addRequest = objectStore.add(data);
          addRequest.onsuccess = () => {
            showToast("Saved");
            this.clearFormData();
          };
          addRequest.onerror = () => {
            console.error("Error saving data");
          };
        }
      };

      getRequest.onerror = () => {
        console.error("Error checking for existing data");
      };
    },

    clearFormData() {
      // Clear all properties of formData
      for (let key in this.formData) {
        if (Array.isArray(this.formData[key])) {
          this.formData[key] = [];
        } else if (
          typeof this.formData[key] === "object" &&
          this.formData[key] !== null
        ) {
          this.formData[key] = {};
        } else {
          this.formData[key] = null;
        }
      }
      this.$formkit.reset("myForm");
    },
    async syncWithDatabase() {
      const transactionRead = this.db.transaction(
        [this.objectStoreName],
        "readonly"
      );
      const objectStoreRead = transactionRead.objectStore(this.objectStoreName);
      const countRequestRead = objectStoreRead.count();

      countRequestRead.onsuccess = () => {
        if (countRequestRead.result === 0) {
          showToast("Nothing to sync");
          return;
        }
      };
      countRequestRead.oncomplete = () => {
        this.db.close();
      };

      const transaction = this.db.transaction(
        [this.objectStoreName],
        "readonly"
      );
      const objectStore = transaction.objectStore(this.objectStoreName);

      const getAllRequest = objectStore.getAll();

      getAllRequest.onsuccess = async () => {
        this.data = getAllRequest.result;

        await this.data.forEach((data) => {
          const formData = new FormData();

          // The code is appending the same 'data' value twice to the formData
          // This likely won't send any data since the keys need to be unique

          // To fix this, we need to append each data field with a unique key
          formData.append("edc_id", data.edc_id);
          formData.append("name", data.name);
          formData.append("name_en", data.name_en);
          formData.append("house_number", data.house_number);
          formData.append("street_number", data.street_number);
          formData.append("village_code", data.village_code);
          formData.append("commune_code", data.commune_code);
          formData.append("district_code", data.district_code);
          formData.append("city", data.city);
          formData.append("latitude", data.latitude);
          formData.append("longitude", data.longitude);
          // this.syncingData = true;
          axios
            .post(
              "https://pwaocrapi.dpdatacenter.com/api/update-data-with-edc-id",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((response) => {
              console.log(response);

              data.location_photo.forEach((document) => {
                document.forEach((d) => {
                  console.log(d);

                  let newFormData = new FormData();
                  newFormData.append("id_card_number", "pilot");
                  newFormData.append("edc_consumer_id", data.edc_id);
                  newFormData.append("file_id", d.file_id);
                  newFormData.append("file_type", d.file_type);
                  newFormData.append("image", d.image);
                  axios
                    .post(
                      "https://pwaocrapi.dpdatacenter.com/api/document/pilot",
                      newFormData,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((response) => {
                      console.log(response);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                });
              });
              console.log(response);
              const transaction = this.db.transaction(
                [this.objectStoreName],
                "readwrite"
              );
              const objectStore = transaction.objectStore(this.objectStoreName);

              if (data.edc_id) {
                const request = objectStore.delete(data.edc_id);

                request.onsuccess = () => {
                  showToast("Data sync successful");
                  // this.formData
                };

                request.onerror = (err) => {
                  console.error(`Error to Delete from indexedDB: ${err}`);
                };
              } else {
                console.error("No edc_id specified for deletion");
                showToast("Error: No edc_id specified for deletion");
              }
            })
            .catch((error) => {
              showToast(
                "Data sync error please check your internet or try again later."
              );
              console.log(error);
            });
        });
      };
    },
    getLocation() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            this.formData.longitude = position.coords.longitude;
            this.formData.latitude = position.coords.latitude;
            //do work work here
            /*
            $.post("url-here", {
                long: position.coords.longitude,
                lat: position.coords.latitude
            }).done(function (response) {
                alert(responsse)
            });
            */
          },
          (error) => {
            alert(error.message);
          },
          {
            enableHighAccuracy: true,
            timeout: 5000,
          }
        );
      } else {
        alert("Geolocation is not supported by this browser.");
      }
    },

    handleSubmit() {},
    handleSearch() {
      axios
        .post("https://pwaocrapi.dpdatacenter.com/api/get-data-with-edc-id", {
          edc_consumer_id: this.searchValue,
        })
        .then((response) => {
          console.log(response.data);
          this.searchResults = response.data.map((item) => ({
            id: item.id,
            ref_id: item.ref_id,
            name: item.name,
            name_en: item.name_en,
            phones: item.phones,
            id_card_number: item.id_card_number,
            edc_id: item.edc_consumer_id,
            house_number: item.house_number,
            street_number: item.street_number,
            village_code: item.village_code,
            commune_code: item.commune_code,
            district: item.district_code,
            city: item.city,
            latitude: item.latitude,
            longitude: item.longitude,
          }));

          if (this.searchResults.length > 0) {
            const result = this.searchResults[0];
            this.formData.edc_id = result.edc_id;
            this.formData.name = result.name;
            this.formData.name_en = result.name_en;
            this.formData.house_number = result.house_number;
            this.formData.street_number = result.street_number;
            this.formData.village_code = result.village_code;
            this.formData.commune_code = result.commune_code;
            this.formData.district_code = result.district;
            this.formData.city = result.city;
            this.formData.latitude = result.latitude;
            this.formData.longitude = result.longitude;
          }
          
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle any errors here
        });
    },
    handleLocationPhoto(event) {
      const files = event.target.files;
      if (!files || files.length === 0) {
        return;
      }
      for (let i = 0; i < files.length; i++) {
        if (files[i].size > 10485760) {
          showToast("file too big");
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          this.formData.location_photo.push(reader.result);
          this.getLocation()
        };
        reader.readAsDataURL(files[i]);
      }
    },
    onClickLeft() {
      this.$router.push("/dashboard");
    },
    checkAttendance() {
      this.$router.push("/attendance");
    },
    checkLeave() {
      this.$router.push("/leave");
    },
  },
};
</script>

<style scoped lang="scss">
#Services {
  padding-top: 0px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 120px;

  .serive-card {
    border-radius: 10px;
    padding: 30px;
    background: #ffffff;
  }

  .box-show-service {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    // box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }
}
</style>
