<template>
    <div id="HomeCom" class="h-full p-11 flex items-center justify-center">
        <div class="wrapper">
            <div class="block">
                <van-loading type="spinner" color="#1989fa" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
            
            }
        },
        methods: {},
        created() {
        }
    }
</script>

<style scoped lang="scss">
   
</style>
