import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/Sign/LoginView.vue';
import RegisterView from '../views/Sign/RegisterView.vue';
import DashboardView from '../views/Home/DashboardView.vue';
import ServiceView from '../views/Services/ServicesView.vue';
import DataView from '../views/pages/DataView.vue';
import PilotView from '@/views/pages/PilotView.vue';

const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/login',
        name: 'login',
        component: LoginView
    },
    {
        path: '/register',
        name: 'register',
        component: RegisterView
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: DashboardView
    },
    {
        path: '/service',
        name: 'service',
        component: ServiceView
    },
    {
        path: '/data',
        name:'DataView',
        component: DataView
    },
    {
        path: '/pilot',
        name:'PilotView',
        component: PilotView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
